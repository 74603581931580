<template>
  <div class="create-maintenance-ui">
    <div class="under-line-title">
      <div class="mb-2">
        <div></div>
        <div class="text-right">
          <div class="report-title d-flex justify-content-between mb-15">
            <h4 class="card-title">
              {{ $t("Maintenance.CarServicing") }}
            </h4>

            <div>
              <b-button
                variant="primary"
                class="v2-back mr-2"
                @click="downloadPdf()"
              >
                <feather-icon
                  icon="DownloadIcon"
                  size="16"
                  class="mr-0 mr-sm-50"
                />
                <span class="d-none d-sm-inline">{{
                  $t("Maintenance.Download")
                }}</span>
              </b-button>
              <b-button
                variant="outline-primary"
                class="v2-back"
                @click="redirectList()"
              >
                <feather-icon
                  icon="ArrowLeftIcon"
                  size="16"
                  class="mr-0 mr-sm-50"
                />
                <span class="d-none d-sm-inline">{{ $t("role.Back") }}</span>
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <b-card>
        <div class="mb-2">
          <div class="text-right">
            <div
              class="report-title d-flex justify-content-between mb-15 mobile-view-re"
            >
              <div
                class="d-flex mb-1 align-items-center justify-content-between"
              >
                <b-media class="align-item unit-list-map-icon-parent-1 pt-0">
                  <template #aside>
                    <span class="unit-list-map-icon-parent">
                      <b-avatar
                        circle
                        class="maintenance-unit-icon"
                        height="10"
                        v-if="instance && instance.unit && instance.unit.icon"
                        :src="instance.unit.icon"
                        variant="primary"
                      />
                    </span>
                  </template>
                  <div style="text-align: left">
                    <h4 class="mb-0">
                      {{ instance && instance.unit && instance.unit.name }}
                    </h4>
                    <small class="text-muted"
                      ><b>{{
                        instance &&
                        instance.serviceDetails &&
                        instance.serviceDetails.name
                      }}</b></small
                    >
                  </div>
                </b-media>
              </div>
              <div>
                <div class="mb-15">
                  <div class="service-last-time">
                    <span>
                      {{ $t("Maintenance.LastServicingDate") }} :
                      {{
                        instance && instance && instance.actual_service_date
                          ? DateTimeConvert(instance.actual_service_date)
                          : ""
                      }}</span
                    >
                  </div>
                  <div class="service-last-time">
                    <span>
                      {{ $t("Maintenance.Odometer") }} :<b>{{
                        instance && instance.odometer
                      }}</b>
                    </span>
                    <span>
                      {{ $t("Maintenance.EngineHour") }} :<b>
                        {{ instance && instance.engine }}</b
                      ></span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="main-bill">
          <div class="d-flex mb-15">
            <div class="maintenance-history-table" table>
              <div
                class="d-flex justify-content-between mb-15 bg-service"
                style="padding: 10px"
              >
                <div class="service-history-title">
                  {{ $t("Maintenance.Services") }}
                </div>

                <div class="" style="min-width: 200px">
                  <div class="service-history-title">
                    {{ $t("Maintenance.Cost") }}
                  </div>
                </div>
              </div>
              <div
                v-for="(service, index) in instance.services"
                :key="index"
                class="d-flex justify-content-between mb-15"
                style="padding: 10px"
              >
                <div class="service-history-label">
                  {{ service.title }}
                </div>
                <div
                  class="service-history-value mr-1"
                  style="min-width: 200px"
                >
                  <div>{{ service.cost }}</div>
                </div>
              </div>
              <div
                class="d-flex justify-content-between mb-15"
                style="padding: 10px"
              >
                <div class="service-history-label"></div>
                <div
                  class="service-history-value mr-1 d-flex justify-content-between"
                  style="min-width: 200px"
                >
                  <div><h4>Total:</h4></div>
                  <div>
                    <b>{{ instance.total_cost }}</b>
                  </div>
                </div>
              </div>
              <div class="ml-2"><h4>Documents</h4></div>
              <div class="ml-3">
                <div
                  v-for="(file, index) in instance.files"
                  :key="index"
                  class="mb-1"
                  style="display: list-item; margin-left: 1em"
                >
                  <b-link
                    class="nav-link"
                    @click="downloadReport(file.url, 'Document')"
                    style="width: auto; display: inline"
                    >{{ file.name || file.url }}
                  </b-link>

                  <!-- <b-skeleton-img
                    style="max-height: 200px; overflow: hidden"
                    v-if="
                      file.pdfLoading && file.mime_type == 'application/pdf'
                    "
                  ></b-skeleton-img>

                  <div style="max-height: 200px; overflow: hidden">
                    <pdf
                      :src="file.url"
                      v-if="file.mime_type == 'application/pdf'"
                    />
                    <b-img
                      height="200px"
                      width="200px"
                      :src="file.url"
                      v-if="['image/png', 'image/jpg'].includes(file.mime_type)"
                    ></b-img>
                  </div>
                  <div>
                    <h4><feather-icon icon="EyeIcon" />view Documents</h4>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <MaintenancePdfView
          :fileUrl="selectedFile"
          v-if="selectedFile && selectedFile.url"
          @close="closePreview"
        ></MaintenancePdfView>
      </b-card>
      <Loader :show="show" />
    </div>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  VBTooltip,
  BFormInput,
  BMedia,
  BAvatar,
  BSkeletonImg,
  BImg,
  BLink
} from "bootstrap-vue";

import constants from "@/utils/constants";
import Loader from "@/layouts/components/Loader.vue";
import SocketIOService from "@/libs/socket/sockets";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import MaintenanceService from "@/libs/api/maintenance-service";
import ReportService from "@/libs/api/report-service";
import moment from "moment";
import MaintenancePdfView from "./Maintenance-pdf-view.vue";
import pdf from "vue-pdf";
import { im } from "mathjs";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    Loader,
    BFormInput,
    VBTooltip,
    BMedia,
    BAvatar,
    BSkeletonImg,
    pdf,
    BImg,
    BLink,
    MaintenancePdfView,
    FeatherIcon
  },
  data() {
    return {
      show: false,
      unit: {
        icon: "https://polygon.getgps.online/app/images/icons/truck/green_truck_icon.svg",
        name: "unit-test"
      },
      unitService: { name: "service-test" },
      instance: {
        unit: {
          id: "",
          name: "",
          icon: ""
        },
        actual_service_date: "",
        odometer: "",
        engine: "",
        serviceDetails: {
          name: "",
          id: ""
        },
        services: [],
        total_cost: 0,
        files: []
      },
      report: {},
      progress: 0,
      selectedFile: {},
      fileUrl: "",
      reportFailed: false,
      selectedService: null,
      isReportEnable: false,
      date: "",
      dateFormattedDate: "",
      isHide: false
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple
  },
  mounted() {
    this.geMaintenanceReminder(this.$route.params.id);
  },
  computed() {},
  watch: {
    maintenance: {
      handler(val) {
        this.isValid();
        if (
          this.maintenance.time &&
          this.date &&
          moment(this.date).isSameOrAfter(this.maintenance.time)
        ) {
          this.maintenance.time = this.date;
        }
      },
      deep: true
    }
  },
  methods: {
    redirectList() {
      //this.$router.push({ name: "maintenance-list" });
      this.$router.go(-1);
    },
    async downloadPdf() {
      const reportInstance = {
        report_name: "",
        processor: "ServiceInstance",
        report_type: "ServiceInstance",
        labels: [],
        entities: [
          {
            id: this.instance.unit.id,
            entity_id: this.instance.unit.id,
            entity_type: 0,
            supported: true,
            account: {
              id: localStorage.getItem("USER_ACCOUNT_ID")
            },
            source: "UNIT",
            selected: true,
            labels: {
              list: [],
              pagination: {
                page: 1,
                page_size: 100,
                total_pages: 0,
                total_records: 0
              }
            },
            is_selected: true
          }
        ],
        sensors: [],
        parameters: [
          {
            desc: "REPORTS.SERVICE_INSTANCE_REPORT.TRANSLATION.DATE_RANGE.DESC",
            name: "date_range",
            label:
              "REPORTS.SERVICE_INSTANCE_REPORT.TRANSLATION.DATE_RANGE.LABEL",
            type: "string",
            value: this.instance.actual_service_date,
            component: "dateRange",
            is_coming_date: true
          },
          {
            name: "reminder_id",
            type: "string",
            value: this.instance.serviceDetails.id
          },
          {
            name: "instance_id",
            type: "string",
            value: this.$route.params.id
          }
        ],
        account_id: localStorage.getItem("USER_ACCOUNT_ID"),
        id: null,
        is_all_units: false
      };
      this.show = true;
      const response = await new ReportService().createReport(reportInstance);
      if (response && response.data) {
        this.report.id = response.data.report_id;
        this.report.status = response.data.status.type;
        setTimeout(() => {
          this.integrateSocket(this.report);
        }, 100);
      }
    },
    numberFormatter(e) {},
    async integrateSocket(report) {
      try {
        if (this.socket) {
          this.socket.disconnect();
          this.socket = null;
        }
        this.socket = new SocketIOService();
        await this.socket.setupSocketConnection();
        if (report.status == "PROCESSING" || report.status == "NOT_STARTED") {
          this.socket.subscribeEventWithFunction(
            `REPORT_PROGRESS${report.id}`,
            (data) => {
              if (Number(data.progress) >= 100) {
                for (let index = 0; index < data.files.length; index++) {
                  const element = data.files[index].file_id;
                  this.downloadReport(element, "Report");
                }
                this.socket.unsubscribeEventWithFunction(
                  `REPORT_PROGRESS${report.id}`
                );
                this.show = false;
              } else if (data.status.status === "COMPLETED") {
                setTimeout(() => {
                  for (let index = 0; index < data.files.length; index++) {
                    const element = data.files[index].file_id;
                    this.downloadReport(element, "Report");
                  }
                  this.show = false;
                }, 500);
              } else if (data.status.status === "FAILED") {
                setTimeout(() => {
                  this.show = false;
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Report download failed",
                      text: data.status.error,
                      icon: "InfoIcon",
                      variant: "danger"
                    }
                  });
                }, 100);
              }
            }
          );
        }
      } catch (error) {}
    },
    async getCreatedReportAPI(id) {
      try {
        this.show = true;
        const me = this;
        let response = await new ReportService().getCreatedReport({
          report_id: id
        });

        if (response && response.data) {
          const report = response.data;
          const data = response.data.files.find(
            (e) => ["PDF", "application/pdf"].includes(e.extension) && e.file_id
          );
          this.fileUrl = data && data.file_id ? data.file_id : "";
          if (!this.fileUrl && response.data.status === "PROCESSING") {
            setTimeout(() => {
              this.integrateSocket(report);
            }, 500);
            return;
          } else if (this.fileUrl && response.data.status === "COMPLETED") {
            setTimeout(() => {
              this.downloadReport(this.fileUrl, "Report");
              this.show = false;
            }, 1000);
          } else if (!this.fileUrl && response.data.status === "FAILED") {
            setTimeout(() => {
              this.show = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Report download failed",
                  text: response.error.message,
                  icon: "InfoIcon",
                  variant: "danger"
                }
              });
            }, 500);
          }
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.show = false;
      }
    },
    async downloadReport(report_url, file) {
      try {
        if (report_url) {
          const link = document.createElement("a");

          link.href = report_url;
          link.target = "_blank";

          link.setAttribute("download", file);

          document.body.appendChild(link);

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setTimeout(() => {
            this.show = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `${file} downloaded successfully`,
                icon: "InfoIcon",
                variant: "success"
              }
            });
          }, 500);
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    showPreview(file) {
      this.selectedFile = file;
      setTimeout(() => {
        this.$bvModal.show("modal-sm-maintenance-preview");
      });
    },
    closePreview() {
      this.selectedFile = {};
      this.$bvModal.show("modal-sm-maintenance-preview");
    },
    async geMaintenanceReminder(id) {
      try {
        this.show = true;
        let response =
          await new MaintenanceService().getCompletedRemindersDetails({
            id
          });
        this.show = false;
        if (response && response.data) {
          this.instance = response.data;
          this.instance.files = this.instance.files.map((file) => {
            let obj = {
              url: file.url ? file.url : file,
              pdfLoading: true,
              name: file.name
            };
            if (!file.mime_type) {
              obj.mime_type = "application/pdf";
            } else {
              obj.mime_type = file.mime_type;
            }

            return obj;
          });

          this.instance.files = this.instance.files.map((file) => {
            let obj = { ...file };
            if (file.mime_type != "application/pdf") {
              return file;
            }
            // Create a loading task and handle promise
            const loadingTask = pdf.createLoadingTask(obj.url);
            obj.pdfLoading = true;
            loadingTask.promise
              .then((pdf) => {
                obj.pdfLoading = false;
              })
              .catch(() => {
                obj.pdfLoading = false;
                obj.error = true;
              });
            return obj;
          });
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
          this.$router.go(-1);
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },

    DateTimeConvert(date) {
      return this.formattedDateTime(date, constants.DATE_TIME_FORMAT);
    }
  },
  beforeDestroy() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  }
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss" scoped>
.bg-service {
  background: #f3f2f7;
}
.main-bill {
  min-height: calc(100vh - 280px);
  max-height: calc(100vh - 280px);
  overflow-y: auto;
  margin-bottom: 10px;
  margin-top: 20px;
  padding: 0px 14x 0px 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.service-last-time {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 21.59px;
  letter-spacing: 0.14000000059604645px;
  text-align: left;
}
.service-history-label {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 21.59px;
  letter-spacing: 0.14000000059604645px;
  text-align: right;
}
.service-history-title {
  font-family: Montserrat;
  font-size: 17.99px;
  font-weight: 500;
  line-height: 27.8px;
  letter-spacing: 0.14000000059604645px;
  text-align: right;
}

.service-history-value {
  text-align: right;
}
.maintenance-history-table {
  // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  // padding: 1rem 1rem 1rem 1rem;
  border-radius: 6px;
  margin-bottom: 15px;
  width: 100%;
  // margin-left: 6px;
}
.bg-img-card {
  width: 200px;
  height: 200px;
  max-height: 200px;
  margin-right: 10px;
  border-radius: 5px;
  border: 1px solid #d8d6de;
  background: #fff;
  overflow: none;
  box-shadow: -1px 6px 10px 0px rgba(0, 0, 0, 0.25);
}
.dark-layout {
  .bg-img-card {
    width: 200px;
    height: 200px;
    max-height: 200px;
    margin-right: 10px;
    border-radius: 5px;
    border: 1px solid #d8d6de;
    background: #373e54;
    overflow: none;
    box-shadow: -1px 6px 10px 0px rgba(0, 0, 0, 0.1);
  }
  .maintenance-history-table {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    background-color: var(--dark-bg);
    // padding: 1rem 1rem 1rem 1rem;
    border-radius: 6px;
  }
  .bg-service {
    background: #373e54;
  }
}
.maintenance-unit-icon {
  img {
    object-fit: contain !important;
  }
}
</style>
