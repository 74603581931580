var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modal-sm-maintenance-preview","centered":"","size":"lg","modal-class":"no-header-modal"},on:{"hide":function (e) {
      _vm.$emit('close', _vm.closePreview);
    }}},[_c('span',{staticStyle:{"display":"none"}},[_vm._v(_vm._s(_vm.fileUrl))]),_c('b-card',[(_vm.fileUrlLink || _vm.fileUrlPageCount)?_c('div',{staticClass:"pdf-card",staticStyle:{"display":"flex","flex-direction":"row","align-items":"center","justify-content":"center"}},[_c('Loader',{attrs:{"show":_vm.pdfLoading}}),(
          _vm.fileUrlPageCount > 1 &&
          !this.pdfLoading &&
          _vm.fileUrl.mime_type == 'application/pdf'
        )?_c('div',{staticClass:"w-100 h-100",staticStyle:{"overflow":"auto"}},_vm._l((_vm.fileUrlPageCount - 1),function(page){return _c('pdf',{key:page,attrs:{"src":_vm.fileUrlLink,"page":page + 1}})}),1):(['image/png', 'image/png'].includes(_vm.fileUrl.mime_type))?_c('div',{staticClass:"img-preview"},[_c('b-img',{attrs:{"src":_vm.fileUrl.url}})],1):(
          (!_vm.fileUrlPageCount || _vm.fileUrlPageCount == 1) && !_vm.pdfLoading
        )?_c('div',[_vm._v(" "+_vm._s(_vm.$t("ReportsData.NoReportsFound"))+" ")]):_vm._e()],1):_vm._e(),(_vm.fileUrl.mime_type == 'application/pdf')?_c('Loader',{attrs:{"show":_vm.pdfLoading}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }