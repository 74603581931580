<template>
  <b-modal
    id="modal-sm-maintenance-preview"
    centered
    size="lg"
    modal-class="no-header-modal"
    @hide="
      (e) => {
        $emit('close', closePreview);
      }
    "
    ><span style="display: none">{{ fileUrl }}</span>
    <b-card>
      <div
        class="pdf-card"
        v-if="fileUrlLink || fileUrlPageCount"
        style="
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        "
      >
        <Loader :show="pdfLoading" />
        <!-- <embed class="w-100 h-100" width="100%" :src="fileUrl" /> -->
        <div
          class="w-100 h-100"
          style="overflow: auto"
          v-if="
            fileUrlPageCount > 1 &&
            !this.pdfLoading &&
            fileUrl.mime_type == 'application/pdf'
          "
        >
          <pdf
            v-for="page in fileUrlPageCount - 1"
            :key="page"
            :src="fileUrlLink"
            :page="page + 1"
          />
        </div>
        <div
          class="img-preview"
          v-else-if="['image/png', 'image/png'].includes(fileUrl.mime_type)"
        >
          <b-img :src="fileUrl.url"></b-img>
        </div>
        <div
          v-else-if="
            (!fileUrlPageCount || fileUrlPageCount == 1) && !pdfLoading
          "
        >
          {{ $t("ReportsData.NoReportsFound") }}
        </div>
      </div>
      <Loader
        :show="pdfLoading"
        v-if="fileUrl.mime_type == 'application/pdf'"
      ></Loader>
    </b-card>
  </b-modal>
</template>

<script>
import { BCard, BImg, BButton, BModal, VBTooltip } from "bootstrap-vue";

import pdf from "vue-pdf";
import Loader from "@/layouts/components/Loader.vue";

export default {
  components: {
    BCard,
    BImg,
    BButton,
    BModal,

    VBTooltip,
    pdf,
    Loader
  },
  directives: {
    "b-tooltip": VBTooltip
  },
  created() {},
  props: ["fileUrl"],
  watch: {
    fileUrl: {
      handler: function (newVal, oldVal) {
        this.integrateSocket();
      },
      deep: true
    }
  },
  data() {
    return {
      progress: 0,
      isReportEnable: false,
      isLoading: false,
      isCardLoading: true,
      isOpen: true,
      reportFailed: false,
      pdfLoading: false,
      fileUrlLink: "",
      fileUrlPageCount: undefined,
      isCreateAPICall: false,
      report_img_url: require("@/assets/images/pages/report-bg.png"),
      report: {},
      showModal: false // Controls the visibility of the modal
    };
  },
  mounted() {
    this.integrateSocket();
  },

  methods: {
    async integrateSocket(report) {
      this.pdfLoading = true;
      this.fileUrlLink = pdf.createLoadingTask(this.fileUrl.url);
      this.fileUrlLink.promise
        .then((pdf) => {
          this.pdfLoading = false;
          this.fileUrlPageCount = pdf.numPages;
        })
        .catch(() => {
          this.pdfLoading = false;
          this.reportFailed = true;
        });
    }
  }
};
</script>

<style scoped lang="scss">
.progressC {
  .card-body {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
}
.img-h-w {
  width: 100%;
  height: 40vh;
}
.pdf-card {
  width: 100%;
  height: calc(100vh - 220px);
  border: 0.5cap solid gray;
}
.report-icon {
  width: 30px;
  cursor: pointer;
}
.is-report-filter-open {
  &#app {
    width: calc(100% - 400px);
  }
}
.report-error-icon {
  path {
    fill: var(--danger);
  }
  position: absolute;
  top: 100px;
  right: 0;
  left: 0;
  margin: 0 auto;
}
.progress-main {
  position: relative;
  min-width: 250px;
  .progress-child {
    position: absolute;
    right: 0;
    left: 0;
    text-align: center;
    top: 325px;
    color: var(--primary);
    font-size: 20px;
    font-weight: 600;
    line-height: 18.8px;
    letter-spacing: 0.14px;
  }
  .progress-child-error {
    position: absolute;
    right: 0;
    left: 0;
    text-align: center;
    top: 250px;
    color: var(--danger);
    font-size: 20px;
    font-weight: 600;
    line-height: 18.8px;
    letter-spacing: 0.14px;
  }
}
.img-preview {
  overflow: hidden;
  height: 100%;
  width: 100%;
}
</style>
